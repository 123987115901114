const cookie = (() => {
    const defaultCookie = () => {
                
        const clickCloseBtn = document.getElementById("close-pg-cookie");
        const pageCookie = document.getElementById("page-cookie");
        const elementIsClicked = "";
        if(clickCloseBtn) {
          clickCloseBtn.addEventListener('click', function(e){
              pageCookie.style.display = "none";
              setCookie("provenCookie",true,"7");
          });

          function setCookie(cname,cvalue,exdays) {
              const d = new Date();
              d.setTime(d.getTime() + (exdays*24*60*60*1000));
              let expires = "expires=" + d.toGMTString();
              document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            }
            
            function getCookie(cname) {
              let name = cname + "=";
              let decodedCookie = decodeURIComponent(document.cookie);
              let ca = decodedCookie.split(';');
              for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                  c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
                }
              }
              return "";
            }
            
            function checkCookie() {
              let user = getCookie("provenCookie");
              if (user != "") {
                pageCookie.style.display = "none";
              }
            }
            checkCookie();
        }
    };
  
  
    const init = () => {
        defaultCookie();
    };
  
    return {
      init,
    };
})();
  
export default cookie;
  