/**
 * Header Layout
 *
 * @file header.js
 */

import $ from "jquery";
import AOS from 'aos';
import 'aos/dist/aos.css';

const header = (() => {
  const fixedHeader = () => {
    const search = document.querySelector('.search');
    const searchMobile = document.querySelector('.search-mobile');
    const searchArea = document.querySelector('.site-search-wrapper');
    const docBody = document.querySelector("body");
    if(search) {
      search.addEventListener('click', function(){
        searchArea.classList.toggle('active')
        docBody.classList.toggle('visible-search');
      });
      // searchMobile.addEventListener('click', function(){
      //   searchArea.classList.toggle('active')
      // });

      searchArea.addEventListener('click', function(event) {
        if(!event.target.closest('.site-search')) {
          searchArea.classList.toggle('active')
          docBody.classList.toggle('visible-search');
        }
      })
    }
  };

  const bannerHeight = () => {
    const banner = document.querySelector('.detail-banner');
    if (banner){
      const bannerImg = banner.querySelector('.img');
      const bannerText = banner.querySelector('.desc');
      if(!bannerText){
        bannerImg.classList.add("no-desc");
      }
    }
  };


  const mobileMenu = () => {
    const mobileMenu = document.querySelector('.mobile-menu');
    const mobileMenuOpen = document.querySelector('.main-nav');
    const mobileMenuItem = document.querySelector(".main-nav__item");
    const docBody2 = document.querySelector("body");
    mobileMenu.addEventListener('click', function(){
      mobileMenuOpen.classList.toggle('active');
      mobileMenu.classList.toggle('active')
      docBody2.classList.toggle('visible-search');
    });
    mobileMenuItem.addEventListener('click', function(){
      mobileMenuItem.classList.toggle('activeMobile');
    });

  };
  const AosJs = () => {
    AOS.init();
  }

  const init = () => {
    fixedHeader();
    mobileMenu();
    bannerHeight();
    AosJs();
  };

  return {
    init,
  };
})();

export default header;
