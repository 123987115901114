/**
 * Tab Component
 *
 * @file tab.js
 */

const tabs = (() => {
  /**
   * Default Tab
   * Project default tab style
   */
  const defaultTab = () => {
  };

  const anchorTabs = () => {
    const anchorItem = document.getElementsByClassName('anchor-item');
    if(anchorItem) {
      for (let i = 0; i < anchorItem.length; i += 1) {
        anchorItem[i].addEventListener('click', function (event) {
          const anchorItemParent = event.currentTarget.parentNode.parentNode;
          console.log(anchorItemParent);
          for (let j = 0; j < anchorItemParent.children.length; j++) {
            anchorItemParent.children[j].classList.remove('active');
          }
          event.currentTarget.parentNode.classList.toggle('active');
        });
      }
    }
  };

  const pageScroll = () => {
    const pageToTop = document.getElementById("page-scroll-top");
    if(pageToTop) {
      pageToTop.addEventListener('click', function(e){
        window.scrollTo({top: 0, behavior: 'smooth'});
        pageToTop.classList.add("hide");
      });
      window.onscroll = function (e) { 
        let pageScrollTop = document.documentElement.scrollTop;
        if (pageScrollTop > 200) {
          pageToTop.classList.remove("hide");
          pageToTop.classList.add("show");
        } else {
          pageToTop.classList.remove("show");
          pageToTop.classList.add("hide");
        }
      } 
    }
  };
  

  const subpageAccordion = () => {
    const accordionItem2 = document.getElementsByClassName('history-tab-item');

    for (let i = 0; i < accordionItem2.length; i += 1) {
      accordionItem2[i].addEventListener('click', function (event) {
        const accTp = event.currentTarget.parentNode;
        for (let i = 0; i < accTp.children.length; i++) {
          accTp.children[i].classList.remove('active');
        }
        event.currentTarget.classList.toggle('active');
        /*multiple*/
        const accHref = this.getAttribute("data-id");
        const accId = document.getElementById(accHref).parentNode;
        for (let i = 0; i < accId.children.length; i++) {
          accId.children[i].classList.remove('active');
        }

        document.getElementById(accHref).classList.add('active');

      });
    }
  };

  const init = () => {
    defaultTab();
    anchorTabs();
    subpageAccordion();
    pageScroll();
  };

  return {
    init,
  };
})();

export default tabs;
