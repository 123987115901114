const accordion = (() => {
  const defaultAccordion = () => {
    const accordionItem = document.getElementsByClassName('js-trigger-accordion');
    let pageItem = document.querySelector('.expand-wrapper')
    if(pageItem) {
      document.querySelectorAll('.full-list-item')[0].classList.add('js-active');

      for (let i = 0; i < accordionItem.length; i += 1) {
        accordionItem[i].addEventListener('click', function (event) {
          if(!event.currentTarget.classList.contains('js-active')) {
            for (let x = 0; x < accordionItem.length; x += 1) {
              accordionItem[x].classList.remove('js-active');
              document.querySelectorAll('.expand-content')[x].classList.remove('js-active');
              document.querySelectorAll('.cls-line__wrapper')[x].classList.remove('js-active')
            }
          }
          event.currentTarget.classList.toggle('js-active');
          const accordionContent = event.currentTarget.querySelector('.expand-content');
          event.currentTarget.querySelector('.cls-line__wrapper').classList.toggle('js-active');
          accordionContent.classList.toggle('js-active');

          let detailItems = document.querySelectorAll('.full-list-item');
          detailItems.forEach((item) => {
            item.classList.remove('js-active');
          })
          let detailItem = document.getElementById(`detail-item-${i}`);
          detailItem.classList.add('js-active');
        });
      }
    }
    
  };


  const defaultScrollClick = () => {
    const scrollItem = document.getElementsByClassName('scroll-more');

    for (let i = 0; i < scrollItem.length; i += 1) {
      scrollItem[i].addEventListener('click', function (event) {
        event.currentTarget.parentNode.classList.toggle('active');
        event.currentTarget.classList.toggle("hide");
      });
    }
  };

  const init = () => {
    defaultAccordion();
    defaultScrollClick();
  };

  return {
    init,
  };
})();

export default accordion;
