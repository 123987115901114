/**
 * Slider Component
 *
 * @file slider.js
 */

import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

import 'swiper/swiper-bundle.css';
import 'swiper/components/effect-fade/effect-fade.min.css';

const slider = (() => {
  /**
   * fullSlider
   * Full width slider settings
   */
  const fullSlider = () => {
    const swiper = new Swiper('.main-slider', {
      pagination: {
        el: '.swiper-pagination',
      },
    });
  };

  const newsSlider = () => {
    const swiperNews = new Swiper('.news-promo-slider', {
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }

  const promoSlider = () => {
    const promoSlide =  new Swiper('.promo-slider', {
      direction: 'vertical',
      loop: true,
      speed: 2000,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      effect: 'fade',
      fadeEffect: { crossFade: true },
      navigation: {
        nextEl: '.promo-next-arrow',
        prevEl: '.promo-prev-arrow'
      },
    });
  }

  const referancesSlider = () => {
    const refSlider = new Swiper('.referances-slider', {
      slidesPerView: 4,
      slidesPerGroup: 4,
      loop: true,
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        340: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20
        }
      },
      navigation: {
        nextEl: '.reference-next',
        prevEl: '.reference-prev'
      },
    });
  }

  const init = () => {
    promoSlider();
    fullSlider();
    newsSlider();
    referancesSlider();
  };

  return {
    init,
  };
})();

export default slider;
